import React from 'react';
import { useMap } from 'react-leaflet';
import { Button, message, Tooltip } from 'antd';

const fallbackCopyTextToClipboard = (text, cb) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      if(successful) {
        cb('Link to the map was coppied to the clipboard');
      } else {
        cb('Link to the map could not be copied to the clipboard');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      cb('Link to the map could not be copied to the clipboard');
    }
  
    document.body.removeChild(textArea);
  }
  
const copyTextToClipboard = (text, cb) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, cb);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
      cb('Link to the map was coppied to the clipboard');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
      cb('Link to the map could not be copied to the clipboard');
    });
}

const CopyUrlMapBtn = ({ token, provider, mode, hideInactive, enableStatus, displayLabels, showTemperatures }) => {
    const map = useMap();
    
    const [messageApi, contextHolder] = message.useMessage();
    const showMessage = (msg) => {
        messageApi.info(msg);
    };

    const copyUrlToClipboard = () => {
        const copyCb = (msg) => {
            // Display confirmation message
            showMessage(msg)
        }
        const url = `${window.location.origin}?token=${token}&zoom=${map.getZoom()}&lat=${map.getCenter().lat}&lng=${map.getCenter().lng}&provider=${provider}&mode=${mode}${hideInactive ? '&hideInactive' : ''}${enableStatus ? '&enableStatus' : ''}${showTemperatures ? '&showTemperatures' : ''}&labels=${displayLabels}`;
        copyTextToClipboard(url, copyCb);
    }
    return (
        <>
            {contextHolder}
            <Tooltip placement="top" title={'Link to this map with its current settings, zoom level, and center will be copied to your clipboard.'}>
                <Button type="primary" onClick={copyUrlToClipboard}>
                    Copy current view URL to clipboard
                </Button>
            </Tooltip>
        </>
    );
};

export default CopyUrlMapBtn;
