import React from 'react';
import Icon from '../Icon/Icon';
import { useDispatch } from 'react-redux';
import { updateParameter } from '../../reducers/parametersReducer';

const TemperatureToggle = ({ showTemperatures }) => {
    const dispatch = useDispatch();
    return (
        <>
            <button className={`modeBtn ${showTemperatures ? 'isOn' : ''}`} onClick={() => dispatch(updateParameter({ showTemperatures: !showTemperatures }))}>
                <Icon iconName='temperature' scale={2.25} color={'#535354'}/>
            </button>
        </>
    );
};

export default TemperatureToggle;
