import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ color, scale, rotate, iconName }) => {

  let icon = null;

  if (iconName === 'status-moving') {
    icon = (
      <svg
        viewBox="0 0 24 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <rect width="24" height="24" rx="3"/>
        <path d="M5 19l6.9-14L19 19l-7.1-5z" fill={"#FFFFFF"}/>
      </svg>
    );
  } else if (iconName === 'status-parked') {
    icon = (
      <svg
        viewBox="0 0 24 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <rect width="24" height="24" rx="3" />
        <path d="M15.2 5.6a4.6 4.6 0 0 1 2.1 1.8 4.5 4.5 0 0 1 .7 2.7 5 5 0 0 1-.7 2.7 5.1 5.1 0 0 1-2.1 1.7 7.9 7.9 0 0 1-3.2.6H9.2V19H6V5h6a7.9 7.9 0 0 1 3.2.6zM14 11.9a2.3 2.3 0 0 0 .8-1.8 2.2 2.2 0 0 0-.8-1.8 2.9 2.9 0 0 0-2.2-.7H9.2v4.9h2.6a3.4 3.4 0 0 0 2.2-.6z"
          fill={"#FFFFFF"}/>
      </svg>
    );
  }  else if (iconName === 'status-idling') {
    icon = (
      <svg
        viewBox="0 0 20 20"
        width='1em'
        height='1em'
        fill='currentColor'
      >
       <g transform="translate(-6682.907 -7117.348)">
        <rect width="20" height="20" rx="3" transform="translate(6682.907 7117.348)" fill="#f7b81c"/>
        <rect width="3" height="11" transform="translate(6689 7122)" fill="#fff"/>
        <rect width="3" height="11" transform="translate(6694 7122)" fill="#fff"/>
      </g>
      </svg>
    );
  }  else if (
    iconName === 'vehicle-type-list-icon-car') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="13" cy="19.5" r="2.5"/>
          <circle cx="35" cy="19.5" r="2.5"/>
          <path d="M41.68,15.03l-.35-1.06c-.19-.58-.73-.96-1.34-.96-1.3,0-2.59-.3-3.76-.89l-1.93-.97c-1.52-.76-3.15-1.29-4.83-1.57l-.66-.11c-1.86-.31-3.75-.31-5.61,0-2.11,.35-4.14,1.1-5.97,2.2l-2.22,1.33-6.04,1.06c-.9,.16-1.68,.69-2.15,1.44h2.19l-.44,.66c-.35,.52-.94,.84-1.57,.84h-.74l-.05,.17c-.13,.54-.07,1.1,.18,1.6l.37,.74c.15,.3,.46,.5,.8,.5h2c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h15.1c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.13-.02,.26-.04,.38l2.61-.65c.55-.14,.93-.63,.93-1.19v-1.02c0-.68-.11-1.35-.32-1.99Zm-17.05-2.48c-.09,.27-.34,.45-.63,.45h-7.48c4.52-3.11,8.95-3,8.95-3l-.85,2.55Zm8.37,.45h-7c-.29,0-.49-.28-.4-.55l.82-2.45s4.9,.1,7.58,2l-1,1Z"/>
        </g>
        <rect fill="none" y="0" width="48" height="24"/>
      </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-buggy') {
    return (
        <svg
          viewBox="0 0 48 24"
          width='1em'
          height='1em'
          fill='currentColor'
        >
          <g>
            <g>
              <path d="M37.05,15.46c1.39,0,2.53,1.13,2.53,2.53s-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53S35.66,15.46,37.05,15.46
                M37.05,12.96c-2.78,0-5.03,2.25-5.03,5.03s2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03S39.83,12.96,37.05,12.96L37.05,12.96z
                "/>
            </g>
            <polygon points="16.27,12.02 19,12 21.72,10.53 21,9 21.56,8.69 23.43,12.42 22.87,12.77 22,11 19.57,12.45 20,14 18.24,14.48 	"/>
            <polygon points="33.57,3.68 32.78,1.4 31.12,0.86 21.21,3.26 18.78,5.15 15.33,10.82 15.83,11.46 19.24,5.64 21.48,3.89 
              31.03,1.48 32.36,1.93 33,4 	"/>
            <polygon points="29,4 29,7 29.5,7.63 29.51,8.78 27,11 27.01,12.35 31.04,11.29 31,7 30,4 	"/>
            <g>
              <path d="M11.2,12.25c0.01,0,0.02,0,0.03,0l-0.16-0.19c-0.51-0.59-1.23-0.97-2.01-1.06L9,11v1.69
                C9.68,12.41,10.42,12.25,11.2,12.25z"/>
              <path d="M39.72,8.5c-0.45-1.58-1.6-2.85-3.13-3.46L34,4l-0.93,0.51l0.68,4.69c0.15,1.02-0.5,1.98-1.5,2.23L18,15l-3-4l-1.92,1.57
                c2.25,0.78,3.87,2.9,3.89,5.4L17,18l14.21,0.01c0.01-3.18,2.6-5.76,5.78-5.76c1.34,0,2.57,0.46,3.55,1.23L41,13L39.72,8.5z"/>
            </g>
            <g>
              <circle cx="37.05" cy="17.96" r="1.5"/>
            </g>
            <g>
              <path d="M11.14,15.46c1.39,0,2.53,1.13,2.53,2.53s-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53S9.74,15.46,11.14,15.46 M11.14,12.96
                c-2.78,0-5.03,2.25-5.03,5.03s2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03S13.92,12.96,11.14,12.96L11.14,12.96z"/>
            </g>
            <circle cx="11.15" cy="17.96" r="1.5"/>
          </g>
        </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-atv') {
    return (
        <svg
          viewBox="0 0 48 24"
          width='1em'
          height='1em'
          fill='currentColor'
        >
          <g>
            <path d="M12,12c-2.78,0-5.03,2.25-5.03,5.03c0,2.78,2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03C17.03,14.25,14.78,12,12,12z
              M12,19.56c-1.39,0-2.53-1.13-2.53-2.53c0-1.39,1.13-2.53,2.53-2.53s2.53,1.13,2.53,2.53C14.53,18.42,13.4,19.56,12,19.56z"/>
            <path d="M35.03,12C32.25,12,30,14.25,30,17.03c0,2.78,2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03
              C40.06,14.25,37.81,12,35.03,12z M35.03,19.56c-1.39,0-2.53-1.13-2.53-2.53c0-1.39,1.13-2.53,2.53-2.53
              c1.39,0,2.53,1.13,2.53,2.53C37.56,18.42,36.42,19.56,35.03,19.56z"/>
            <circle cx="35.02" cy="17" r="1.5"/>
            <circle cx="12.01" cy="17" r="1.5"/>
            <path d="M17.05,9c0.58,0,1.1,0.35,1.33,0.88l2.24,5.24c0.23,0.54,0.76,0.88,1.34,0.88h3c0.63,0,1.19-0.37,1.44-0.95l2.19-5.1
              C28.84,9.37,29.41,9,30.03,9H37L38,9l-1.32-1.31C36.25,7.25,35.66,7,35.04,7h-7.08c-0.6,0-1.17,0.28-1.53,0.76l-0.65,0.87
              c-0.14,0.19-0.42,0.19-0.56,0l-0.65-0.88C24.21,7.28,23.66,7,23.06,7H21l-0.29-0.29C20.26,6.26,19.64,6,19,6H18V4h3V3h-4.04
              C16.43,3,16,3.43,16,3.96V4c0,0.55-0.45,1-1,1c-1.1,0-2,0.9-2,2h-1l-1,2H17.05z"/>
            <path d="M32,11h8v-1h-8.3c-1.03,0-1.96,0.61-2.36,1.56l-2.07,4.83C27.11,16.76,26.74,17,26.34,17h-5.81
              c-0.32,0-0.61-0.19-0.74-0.48l-2.47-5.68c-0.22-0.52-0.73-0.85-1.3-0.85L8,10v1h7.34c0.4,0,0.76,0.24,0.92,0.61l2.37,5.52
              c0.23,0.53,0.75,0.87,1.32,0.87h7.1c0.58,0,1.1-0.34,1.33-0.87l2.27-5.24C30.88,11.35,31.41,11,32,11z"/>
            <polygon points="11.48,7 10.48,7 9.48,9 10.48,9 		"/>
          </g>
        </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-dumptruck') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <path d="M18,5l-1.04-3H10v3h4l4,11h5.19c0.81-1.21,2.18-2,3.74-2c1.56,0,2.92,0.8,3.72,2h0.69c0.81-1.2,2.17-2,3.72-2
            c1.56,0,2.93,0.79,3.74,2H41l4-5V5H18z"/>
          <path d="M39.28,17c0.17,0.47,0.28,0.97,0.28,1.5s-0.11,1.03-0.28,1.5H41l0.06-3H39.28z"/>
          <path d="M22.7,17H17L13.35,6.95C13.14,6.38,12.6,6,11.99,6H6C4.9,6,4,6.9,4,8H3v1h1v1.99V12c-0.53,0-1,0.42-1,0.95V18v1.04
            C3,19.57,3.43,20,3.97,20h2.96c-0.17-0.47-0.28-0.97-0.28-1.5c0-2.49,2.01-4.5,4.5-4.5s4.5,2.01,4.5,4.5c0,0.53-0.11,1.03-0.28,1.5
            H21h1.7c-0.17-0.47-0.28-0.97-0.28-1.5S22.54,17.47,22.7,17z M8,12H5V9h3V12z M11,10c0,1.11-0.9,2-2,2H8.99V9H11V10z"/>
          <g>
            <circle cx="26.93" cy="18.5" r="3.5"/>
          </g>
          <g>
            <circle cx="11.15" cy="18.5" r="3.5"/>
          </g>
          <g>
            <circle cx="35.06" cy="18.5" r="3.5"/>
          </g>
        </g>
      </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-eBike') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <g>
            <g>
              <g>
                <path d="M15.5,13.5c1.95,0,3.53,1.58,3.53,3.53c0,1.95-1.58,3.53-3.53,3.53c-1.95,0-3.53-1.58-3.53-3.53
                  C11.98,15.08,13.56,13.5,15.5,13.5 M15.5,12c-2.78,0-5.03,2.25-5.03,5.03s2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03
                  S18.28,12,15.5,12L15.5,12z"/>
              </g>
            </g>
            <g>
              <path d="M31.5,13.5c1.95,0,3.53,1.58,3.53,3.53c0,1.95-1.58,3.53-3.53,3.53c-1.95,0-3.53-1.58-3.53-3.53
                c0-0.18,0.01-0.36,0.04-0.53l-1.47-0.31c-0.05,0.27-0.07,0.55-0.07,0.84c0,2.78,2.25,5.03,5.03,5.03c2.78,0,5.03-2.25,5.03-5.03
                S34.28,12,31.5,12l0,0c-0.98,0-1.89,0.28-2.66,0.76l1.06,1.12C30.39,13.64,30.93,13.5,31.5,13.5"/>
            </g>
          </g>
          <g>
            <path d="M19.49,8.87L19.49,8.87l5.59,6.42L27.06,9H26V8h5v1h-2.02C28.52,9,28.12,9.31,28,9.75l-0.31,1.14l4.15,4.78
              C32.3,16.19,31.92,17,31.23,17l-5.25,0.01c-0.62-0.01-1.21-0.28-1.61-0.75L19,10l-3,7h-1l3.53-8.76L19,7h-3V6h3.36
              c0.57,0,0.96,0.56,0.77,1.09L19.49,8.87z"/>
          </g>
          <g>
            <polygon points="22,5 23,8 21,8 24,12 23,9 25,9 22,5 		"/>
          </g>
          <rect fill="none" width="48" height="24"/>
        </g>
      </svg>
    );
  }  else if (iconName === 'fleet-vehicle-type-eCargoBike' || iconName === 'vehicle-type-list-icon-eCargoBike') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <path strokeWidth="0" d="m35.85,12.12c-1.26-.28-2.43-.06-3.43.47l-1.48-1.7.52-1.88h2.8v-1h-5v1h1.06l-1.99,6.29-.2.6h-6.83V7.06c0-.56.45-1.01,1.01-1.01h1.96v-1.05h-1.99c-1.11,0-2.01.9-2.01,2.01v.99H7.26v9.01h1.95v.02c-.18,1.67.62,3.31,2.06,4.17,3.94,2.34,8-.44,8-4.17,0,0,0-.01,0-.02h10.48s0,.02,0,.02c-.13,1.63.58,3.26,1.97,4.11,4.24,2.6,8.65-.77,8.02-4.9-.31-2.01-1.89-3.68-3.88-4.12Zm-20.93,8.37c-2.48.47-4.63-1.67-4.15-4.15.26-1.38,1.39-2.51,2.77-2.77,2.48-.47,4.63,1.67,4.15,4.15-.26,1.38-1.39,2.51-2.77,2.77Zm19.84.07c-1.95,0-3.54-1.59-3.53-3.55h0s3.25,0,3.25,0c.69,0,1.07-.82.61-1.34l-1.64-1.89c.84-.34,1.8-.45,2.9.18s1.87,1.69,1.92,2.92c.09,2.02-1.53,3.69-3.52,3.69Z"/>
          <polygon strokeWidth="0" points="24.26 8 25.26 11 23.26 11 26.26 15 25.26 12 27.26 12 24.26 8"/>
          <circle strokeWidth="0" cx="14.26" cy="17" r="1"/>
        </g>
        <rect strokeWidth="0" fill="none" y="0" width="48" height="24"/>
      </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-doubleDecker') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="11" cy="20.5" r="2.5"/>
          <circle cx="37" cy="20.5" r="2.5"/>
          <path d="M45.39,1H4.07C2.93,1,2,1.93,2,3.07V7C1.36,7.64,1,8.51,1,9.42v9.67C1,20.14,1.86,21,2.92,21h4.63
            c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5h19.1
            c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5h2.61
            c0.61,0,1.21-0.18,1.72-0.52l1.37-0.92c0.53-0.35,0.85-0.94,0.85-1.58V2.61C47,1.72,46.28,1,45.39,1z M2.54,2.96h4.9
            c0.28,0,0.51,0.23,0.51,0.51v2.99c0,0.28-0.23,0.51-0.51,0.51h-4.9V2.96z M1.5,19v-1.71c0.47,0,0.86,0.38,0.86,0.86S1.98,19,1.5,19
            z M7.99,13.96c0,1.13-0.91,2.04-2.04,2.04H1.5v-6h6.49V13.96z M15,13.49C15,13.77,14.78,14,14.5,14H9.51C9.23,14,9,13.77,9,13.49
            V10.5C9,10.22,9.23,10,9.51,10h4.99C14.78,10,15,10.22,15,10.5V13.49z M15,6.46c0,0.28-0.23,0.51-0.51,0.51H9.51
            C9.23,6.97,9,6.74,9,6.46V3.47c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V6.46z M22,13.49
            C22,13.77,21.78,14,21.5,14h-4.99C16.23,14,16,13.77,16,13.49V10.5c0-0.28,0.23-0.51,0.51-0.51h4.99C21.78,10,22,10.22,22,10.5
            V13.49z M22,6.46c0,0.28-0.23,0.51-0.51,0.51h-4.99C16.23,6.97,16,6.74,16,6.46V3.47c0-0.28,0.23-0.51,0.51-0.51h4.99
            c0.28,0,0.51,0.23,0.51,0.51V6.46z M28.93,13.49c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V10.5
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V13.49z M28.93,6.46c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V3.47c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V6.46z M35.93,13.49
            c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V10.5c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51
            V13.49z M35.93,6.46c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V3.47c0-0.28,0.23-0.51,0.51-0.51h4.99
            c0.28,0,0.51,0.23,0.51,0.51V6.46z M42.93,13.49c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V10.5
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V13.49z M42.93,6.46c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V3.47c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V6.46z"/>
        </g>
      </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-coach') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="11" cy="19.5" r="2.5"/>
          <path d="M45.43,6.28H47V5.61C47,4.72,46.28,4,45.39,4H3.92C2.86,4,2,4.86,2,5.92v8.69l-0.12,0.1C1.33,15.13,1,15.8,1,16.5v1.59
            C1,19.14,1.86,20,2.92,20h4.63c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5c1.93,0,3.5,1.57,3.5,3.5
            c0,0.17-0.03,0.34-0.05,0.5h19.1c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5c1.93,0,3.5,1.57,3.5,3.5
            c0,0.17-0.03,0.34-0.05,0.5h2.61c0.61,0,1.21-0.18,1.72-0.52l1.37-0.92c0.53-0.35,0.85-0.94,0.85-1.58v-6.71h-1.57
            c-0.28,0-0.51-0.23-0.51-0.51V6.78C44.93,6.5,45.15,6.28,45.43,6.28z M1.5,18v-1.71c0.47,0,0.86,0.38,0.86,0.86
            C2.36,17.62,1.98,18,1.5,18z M8.99,6.28v3.34c0,1.59-1.02,2.99-2.53,3.48L2.5,14.39V6.28H8.99z M16,9.77
            c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V6.78c0-0.28,0.23-0.51,0.51-0.51h4.99C15.78,6.28,16,6.5,16,6.78
            V9.77z M23,9.77c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V6.78c0-0.28,0.23-0.51,0.51-0.51h4.99
            C22.78,6.28,23,6.5,23,6.78V9.77z M29.93,9.77c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V6.78
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V9.77z M36.93,9.77c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V6.78c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V9.77z M43.93,9.77
            c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V6.78c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51
            V9.77z"/>
          <circle cx="37" cy="19.5" r="2.5"/>
        </g>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-cityBus') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="11.78" cy="19.5" r="2.5"/>
          <circle cx="32.53" cy="19.5" r="2.5"/>
          <path d="M44.43,8H47V5.61C47,4.72,46.28,4,45.39,4H2.92C1.86,4,1,4.86,1,5.92v12.17C1,19.14,1.86,20,2.92,20h5.42
            c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5h13.85
            c-0.02-0.16-0.05-0.33-0.05-0.5c0-1.93,1.57-3.5,3.5-3.5c1.93,0,3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5h7.08
            c0.61,0,1.21-0.18,1.72-0.52l1.37-0.92c0.53-0.35,0.85-0.94,0.85-1.58V12h-2.57c-0.28,0-0.51-0.23-0.51-0.51V8.5
            C43.93,8.22,44.15,8,44.43,8z M1.5,18v-1.71c0.47,0,0.86,0.38,0.86,0.86C2.36,17.62,1.98,18,1.5,18z M7.99,11.96
            c0,1.13-0.91,2.04-2.04,2.04H1.5V8h6.49V11.96z M15,11.49C15,11.77,14.78,12,14.5,12H9.51C9.23,12,9,11.77,9,11.49V8.5
            C9,8.22,9.23,8,9.51,8h4.99C14.78,8,15,8.22,15,8.5V11.49z M22,18.16c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V8.5C16,8.22,16.23,8,16.51,8h4.99C21.78,8,22,8.22,22,8.5V18.16z M28.93,11.49
            c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V8.5c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51
            V11.49z M35.93,11.49c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V8.5c0-0.28,0.23-0.51,0.51-0.51h4.99
            c0.28,0,0.51,0.23,0.51,0.51V11.49z M42.93,18.29c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V8.5
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V18.29z"/>
          <path d="M11,3.61l0.57-0.57c0.28-0.28,0.65-0.43,1.04-0.43h4.75c0.39,0,0.76,0.15,1.04,0.43l0.59,0.59L11,3.61z"/>
          <path d="M27.94,3.61l0.57-0.57c0.28-0.28,0.65-0.43,1.04-0.43h4.75c0.39,0,0.76,0.15,1.04,0.43l0.59,0.59L27.94,3.61z"/>
        </g>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-allTerrainBus') {
    return (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <g>
          <g>
            <circle cx="35.54" cy="20" r="2.86"/>
          </g>
          <path d="M44.98,2H9.06C8.4,2,7.78,2.33,7.4,2.87L1.66,11.1c-0.41,0.59-0.63,1.29-0.63,2V15h1.91c0.47,0,0.86,0.38,0.86,0.86
            c0,0.47-0.38,0.86-0.86,0.86l-1.91,0.02V19c0,0.55,0.45,1,1,1h7l1.58-3.19c0.25-0.5,0.75-0.81,1.31-0.81h3.19
            c0.55,0,1.06,0.31,1.31,0.81l1.6,3.19h13.01l1.58-3.19c0.25-0.5,0.75-0.81,1.31-0.81h3.19c0.55,0,1.06,0.31,1.31,0.81L40,20h1.71
            c0.84,0,1.65-0.27,2.32-0.78l2.24-1.69c0.44-0.34,0.71-0.86,0.71-1.42V4C46.98,2.89,46.08,2,44.98,2z M10.73,9.25
            c0,1.13-0.91,2.04-2.04,2.04H2.53l4.15-6h4.05V9.25z M17.88,8.78c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51
            V5.79c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V8.78z M24.8,8.78c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V5.79c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V8.78z M31.8,8.78
            c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V5.79c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51
            V8.78z M38.8,8.78c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V5.79c0-0.28,0.23-0.51,0.51-0.51h4.99
            c0.28,0,0.51,0.23,0.51,0.51V8.78z M45.69,8.78c0,0.28-0.23,0.51-0.51,0.51H40.2c-0.28,0-0.51-0.23-0.51-0.51V5.79
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V8.78z"/>
          <g>
            <circle cx="13.54" cy="20" r="2.86"/>
          </g>
        </g>
      </svg>
    );
  }  else if (iconName === 'vehicle-type-list-icon-snowmobile') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <path d="M40.22,18.47l-2.24-1.54L38,13.71c0-0.45-0.18-0.89-0.5-1.21c-0.32-0.32-0.75-0.5-1.2-0.5h-8.26
            c-0.63,0-1.21,0.36-1.5,0.92L25,16h-6.88c-0.99,0-1.65-1.02-1.24-1.93L21,5h-1.15c-0.54,0-1.04,0.25-1.37,0.68L16,9l-2.26,0.9
            c-1.81,0.72-3.45,1.81-4.83,3.18c-0.5,0.5-0.5,1.32,0,1.83l0.26,0.26c0.54,0.54,1.21,0.95,1.95,1.18l2.37,0.74L12,20H8.92
            c-0.58,0-1.15-0.23-1.56-0.65L6,18v1.01c0,0.63,0.25,1.24,0.7,1.68l0.61,0.61C7.75,21.75,8.36,22,8.98,22H22v-0.52l-4.06-1
            C16.65,20.16,15.33,20,14,20l1.18-2.38l7.74,2.42l0.58,1.05c0.31,0.56,0.9,0.91,1.55,0.91h14.92c0.57,0,1.04-0.47,1.04-1.04v-1.01
            C41,19.36,40.71,18.8,40.22,18.47z M25.97,20.94c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S26.52,20.94,25.97,20.94z M30.13,20.94
            c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S30.68,20.94,30.13,20.94z M34.29,20.94c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1
            S34.84,20.94,34.29,20.94z M38.44,20.94c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S39,20.94,38.44,20.94z"/>
        </g>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-bus') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <circle cx="11" cy="19.5" r="2.5"/>
        <path d="M44.43,8h2.57v-2.38c0-.89-.72-1.61-1.61-1.61H2.92c-1.06,0-1.91,.86-1.91,1.91v12.17c0,1.06,.86,1.92,1.92,1.92H7.55c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h19.1c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h2.61c.61,0,1.21-.18,1.72-.52l1.37-.92c.53-.35,.85-.94,.85-1.58v-4.98h-2.57c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51ZM1.5,18v-1.71c.47,0,.86,.38,.86,.86s-.38,.86-.86,.86Zm6.49-6.04c0,1.13-.91,2.04-2.04,2.04H1.5v-6H7.99v3.96Zm7.01-.46c0,.28-.23,.51-.51,.51h-4.99c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51h4.99c.28,0,.51,.23,.51,.51v2.99Zm7,0c0,.28-.23,.51-.51,.51h-4.99c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51h4.99c.28,0,.51,.23,.51,.51v2.99Zm6.93,0c0,.28-.23,.51-.51,.51h-4.99c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51h4.99c.28,0,.51,.23,.51,.51v2.99Zm7,0c0,.28-.23,.51-.51,.51h-4.99c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51h4.99c.28,0,.51,.23,.51,.51v2.99Zm7,0c0,.28-.23,.51-.51,.51h-4.99c-.28,0-.51-.23-.51-.51v-2.99c0-.28,.23-.51,.51-.51h4.99c.28,0,.51,.23,.51,.51v2.99Z"/>
        <circle cx="37" cy="19.5" r="2.5"/>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-motorcycle') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <path d="M36.03,12c-1.8,0-3.37,.95-4.25,2.37l-.77-.37,3-3h-1l-4.98,2.03c-.64,.26-1.13,.81-1.32,1.47l-.55,1.96-.19-.08c-.54-.22-.82-.81-.63-1.36l.34-1.01c.19-.58-.07-1.21-.61-1.48l-9.05-4.53h-3l2.5-1.5,2.5-1.5-1-2-2.57,1.71c-2.21,1.47-3.79,3.71-4.43,6.29h1l1-1,1.65,2.07c-.22-.03-.43-.07-.65-.07-2.78,0-5.03,2.25-5.03,5.03s2.25,5.03,5.03,5.03c2.62,0,4.75-2.02,4.98-4.58l2.02,2.52h11l-1-2-2.14-.86,1.14-1.14,2.07,.37c-.03,.22-.07,.43-.07,.65,0,2.78,2.25,5.03,5.03,5.03s5.03-2.25,5.03-5.03-2.25-5.03-5.03-5.03ZM13,20.56c-1.95,0-3.53-1.58-3.53-3.53s1.58-3.53,3.53-3.53,3.53,1.58,3.53,3.53-1.58,3.53-3.53,3.53Zm23.03,0c-1.95,0-3.53-1.58-3.53-3.53,0-.13,.02-.26,.04-.39l1.98,.36s0,0,0,0c0,.83,.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5c-.4,0-.77,.16-1.04,.42l-1.85-.89c.64-.92,1.7-1.53,2.9-1.53,1.95,0,3.53,1.58,3.53,3.53s-1.58,3.53-3.53,3.53Z"/>
          <path d="M27,12l11-4-.34-.34c-.42-.42-.99-.66-1.59-.66h-3.09c-.6,0-1.15,.34-1.41,.87l-.14,.28c-.26,.52-.79,.84-1.37,.84h-3.1c-.58,0-1.12-.33-1.38-.85l-.17-.33c-.25-.5-.76-.82-1.32-.82h-4.16c-.59,0-1.16,.24-1.59,.66l-.34,.34,9,4Z"/>
          <circle cx="13.01" cy="17" r="1.5"/>
        </g>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-trailer') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <path d="M40.04,12.96V7.02c0-1.12-.9-2.02-2.02-2.02H17.95c-1.08,0-1.95,.87-1.95,1.95v6.01h24.04Z"/>
        <path d="M31.58,18.27c-.29,0-.52,.23-.52,.52s.23,.52,.52,.52,.52-.23,.52-.52-.23-.52-.52-.52Z"/>
        <g>
          <path d="M40.04,16.99v-3.03H16v3.04H8v-1l-3,1v1h11v.35c0,.34,.27,.61,.61,.61h4.54c0-.06-.02-.12-.02-.18,0-1.93,1.57-3.5,3.5-3.5s3.33,1.42,3.47,3.22c.14-1.8,1.63-3.22,3.47-3.22,1.93,0,3.5,1.57,3.5,3.5,0,.06-.01,.12-.02,.18l5.88,.03v-.96c0-.53-.4-.95-.9-1.03Z"/>
          <path d="M24.64,16.29c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5,2.5-1.12,2.5-2.5-1.12-2.5-2.5-2.5Zm0,3.52c-.56,0-1.02-.46-1.02-1.02s.46-1.02,1.02-1.02,1.02,.46,1.02,1.02-.46,1.02-1.02,1.02Z"/>
        </g>
        <path d="M31.58,16.29c-1.38,0-2.5,1.12-2.5,2.5s1.12,2.5,2.5,2.5,2.5-1.12,2.5-2.5-1.12-2.5-2.5-2.5Zm0,3.52c-.56,0-1.02-.46-1.02-1.02s.46-1.02,1.02-1.02,1.02,.46,1.02,1.02-.46,1.02-1.02,1.02Z"/>
        <path d="M24.64,18.27c.29,0,.52,.23,.52,.52s-.23,.52-.52,.52-.52-.23-.52-.52,.23-.52,.52-.52"/>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-truck') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <path d="M14.1,2c-.61,0-1.1,.49-1.1,1.1V14.9c0,.61,.49,1.1,1.1,1.1H48V2H14.1Z"/>
          <circle cx="26" cy="19.5" r="2.5"/>
          <circle cx="34.06" cy="19.5" r="2.5"/>
          <path d="M31.61,17h-3.17c.65,.64,1.06,1.52,1.06,2.5,0,.17-.03,.34-.05,.5h1.16c-.02-.16-.05-.33-.05-.5,0-.98,.41-1.86,1.06-2.5Z"/>
          <path d="M36.5,17c.65,.64,1.06,1.52,1.06,2.5,0,.17-.03,.34-.05,.5h3.49l.04-2h.91v-1h-5.44Z"/>
          <circle cx="7" cy="19.5" r="2.5"/>
          <path d="M23.56,17H12V4h-2l-4,2h-2c-1.1,0-2,.89-2,2h0s-1,0-1,0v1h1v3c-.53,0-1,.42-1,.95v6.09c0,.53,.43,.96,.96,.96h1.59c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h12.1c-.02-.16-.05-.33-.05-.5,0-.98,.41-1.86,1.06-2.5ZM6,12H3v-3h3v3Zm3-2c0,1.11-.9,2-2,2h0v-3h2.01v1Z"/>
        </g>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-van') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="13" cy="19.5" r="2.5"/>
          <circle cx="34.93" cy="19.5" r="2.5"/>
          <path d="M40.01,4H17.01c-1.01,0-2.01,1-2.01,1L5.74,13.34c-.47,.42-.74,1.03-.74,1.66v4.01c0,.55,.45,1,1,1h3.55c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h15.03c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h1.62c1.11,0,2-.9,2-2V6c0-1.1-.89-1.99-1.99-1.99ZM5.49,16.81v-1.71c.47,0,.86,.38,.86,.86s-.38,.86-.86,.86Zm11.51-5.81c0,.55-.44,.99-.99,.99h-7.65l5.64-5h3v4.01Z"/>
        </g>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-shuttle') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="14.91" cy="18.93" r="2.5"/>
          <circle cx="32.89" cy="18.79" r="2.5"/>
          <path d="M34,5H24H14c-3.76,0-7,3.24-7,7l0.08,5.03c0,1.06,0.83,2.09,1.88,2.09l2.47-0.02c0-0.06-0.02-0.11-0.02-0.16
            c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.04-0.01,0.07-0.01,0.11L24,19h5.41c0-0.07-0.02-0.14-0.02-0.21
            c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.07-0.02,0.14-0.02,0.21H39c1.06,0,1.96-0.74,1.96-1.8v-5.27
            C40.96,8.17,37.77,5,34,5z M16,12.26c0,0.41-0.33,0.74-0.74,0.74H9.95C9.43,13,9,12.58,9,12.05v-0.53C9,9.03,11.03,7,13.53,7h1.67
            C15.64,7,16,7.36,16,7.81V12.26z M20.96,12.09c0,0.46-0.38,0.84-0.84,0.84h-2.34c-0.46,0-0.84-0.38-0.84-0.84V7.93
            c0-0.46,0.38-0.84,0.84-0.84h2.34c0.46,0,0.84,0.38,0.84,0.84V12.09z M25.97,12.09c0,0.46-0.38,0.84-0.84,0.84h-2.34
            c-0.46,0-0.84-0.38-0.84-0.84V7.93c0-0.46,0.38-0.84,0.84-0.84h2.34c0.46,0,0.84,0.38,0.84,0.84V12.09z M30.98,12.09
            c0,0.46-0.38,0.84-0.84,0.84H27.8c-0.46,0-0.84-0.38-0.84-0.84V7.93c0-0.46,0.38-0.84,0.84-0.84h2.34c0.46,0,0.84,0.38,0.84,0.84
            V12.09z M39,12.05c0,0.52-0.43,0.95-0.95,0.95H32.8c-0.44,0-0.8-0.36-0.8-0.8V7.79C32,7.36,32.36,7,32.8,7h1.58
            C36.93,7,39,9.07,39,11.63V12.05z"/>
        </g>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-miniBus') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <g>
          <circle cx="13" cy="19.5" r="2.5"/>
          <circle cx="34.93" cy="19.5" r="2.5"/>
          <path d="M40.01,4h-23C16,4,15,5,15,5l-9.26,8.33C5.27,13.76,5,14.36,5,15V19c0,0.55,0.45,1,1,1h3.55c-0.02-0.16-0.05-0.33-0.05-0.5
            c0-1.93,1.57-3.5,3.5-3.5c1.93,0,3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5h15.03c-0.02-0.16-0.05-0.33-0.05-0.5
            c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,0.17-0.03,0.34-0.05,0.5H40c1.11,0,2-0.9,2-2V6C42,4.89,41.11,4,40.01,4z M5.49,16.81
            V15.1c0.47,0,0.86,0.38,0.86,0.86C6.35,16.43,5.97,16.81,5.49,16.81z M17,11.01c0,0.55-0.44,0.99-0.99,0.99H8.36L14,7h3V11.01z
            M24.95,10.58c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V7.59c0-0.28,0.23-0.51,0.51-0.51h4.99
            c0.28,0,0.51,0.23,0.51,0.51V10.58z M31.95,10.58c0,0.28-0.23,0.51-0.51,0.51h-4.99c-0.28,0-0.51-0.23-0.51-0.51V7.59
            c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V10.58z M38.88,10.58c0,0.28-0.23,0.51-0.51,0.51h-4.99
            c-0.28,0-0.51-0.23-0.51-0.51V7.59c0-0.28,0.23-0.51,0.51-0.51h4.99c0.28,0,0.51,0.23,0.51,0.51V10.58z"/>
        </g>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-truckBanke') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <circle cx="10.97" cy="19" r="3"/>
        <circle cx="26.38" cy="19" r="3"/>
        <circle cx="34" cy="19" r="3"/>
        <path d="M43.74,11.39l-5.68-7.1h-2l4.93,16h2l1.44-5.32c.35-1.24.09-2.57-.7-3.58Z"/>
        <path d="M33.07,4.29v-1h-1v1h-3v-1h-1v1h-3v-1h-1v1h-3v-1h-1v1h-3l-1-1h-4v2h3v15h7.46c-.08-.32-.14-.94-.14-1.29,0-2.21,1.79-4,4-4,1.79,0,3.29,1.19,3.8,2.81.51-1.62,2.01-2.81,3.8-2.81,2.21,0,4,1.79,4,4,0,.35-.06.97-.14,1.29h2.16l-4.93-16h-2Z"/>
        <path d="M8.98,6c-.71,0-1.38.32-1.83.87l-3.08,4.41c-.44.54-1,1.31-1,2v7l4.07-.02c-.08-.32-.16-.92-.16-1.27,0-2.21,1.79-4,4-4,1.22,0,2.36.56,3.1,1.42V6h-5.09ZM11.07,11.29h-6l3-4h3v4Z"/>
      </svg>
    );
  } else if (iconName === 'vehicle-type-list-icon-ambulance') {
    icon = (
      <svg
        viewBox="0 0 48 24"
        width='1em'
        fill='currentColor'
      >
        <circle cx="13" cy="19.5" r="2.5"/>
        <circle cx="34.93" cy="19.5" r="2.5"/>
        <rect fill="none" x="0" y="0" width="48" height="24"/>
        <path d="M40.01,4H24v-1c0-.55-.45-1-1-1h-1c-.55,0-1,.45-1,1v1h-3.99c-1.01,0-2.01,1-2.01,1L5.74,13.34c-.47,.42-.74,1.03-.74,1.66v4.01c0,.55,.45,1,1,1h3.55c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h15.03c-.02-.16-.05-.33-.05-.5,0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5c0,.17-.03,.34-.05,.5h1.62c1.11,0,2-.9,2-2V6c0-1.1-.89-1.99-1.99-1.99ZM5.49,16.81v-1.71c.47,0,.86,.38,.86,.86s-.38,.86-.86,.86Zm11.51-5.81c0,.55-.44,.99-.99,.99h-7.65l5.64-5h3v4.01Zm14,1.99h-3v3h-3v-3h-3v-3h3v-3.01h3v3.01h3v3Z"/>
      </svg>
    );
  }   else if (iconName === 'update-time') {
    icon = (
      <svg
        viewBox="0 0 17 16"
        width='1em'
        height='1em'
        fill='currentColor'
      >
        <path d="M8.5 14C7.66667 14 6.88611 13.8417 6.15833 13.525C5.43056 13.2083 4.79722 12.7806 4.25833 12.2417C3.71944 11.7028 3.29167 11.0694 2.975 10.3417C2.65833 9.61389 2.5 8.83333 2.5 8C2.5 7.16667 2.65833 6.38611 2.975 5.65833C3.29167 4.93056 3.71944 4.29722 4.25833 3.75833C4.79722 3.21944 5.43056 2.79167 6.15833 2.475C6.88611 2.15833 7.66667 2 8.5 2C9.41111 2 10.275 2.19444 11.0917 2.58333C11.9083 2.97222 12.6 3.52222 13.1667 4.23333V2.66667H14.5V6.66667H10.5V5.33333H12.3333C11.8778 4.71111 11.3167 4.22222 10.65 3.86667C9.98333 3.51111 9.26667 3.33333 8.5 3.33333C7.2 3.33333 6.09722 3.78611 5.19167 4.69167C4.28611 5.59722 3.83333 6.7 3.83333 8C3.83333 9.3 4.28611 10.4028 5.19167 11.3083C6.09722 12.2139 7.2 12.6667 8.5 12.6667C9.66667 12.6667 10.6861 12.2889 11.5583 11.5333C12.4306 10.7778 12.9444 9.82222 13.1 8.66667H14.4667C14.3 10.1889 13.6472 11.4583 12.5083 12.475C11.3694 13.4917 10.0333 14 8.5 14ZM10.3667 10.8L7.83333 8.26667V4.66667H9.16667V7.73333L11.3 9.86667L10.3667 10.8Z"/>
      </svg>
    );
  }  else if (iconName === 'temperature') {
    icon = (
        <svg 
            viewBox="0 0 256 256"
            width='1em'
            height='1em'
            fill='currentColor'
        >
            <g>
                <g>
                    <path fill="#000000" d="M161.4,187c0-9.4-4-18.2-11.2-24.7l-8.5-7.6v-11.4v-26.6l-27.5-6.9v33.5v11.4l-8.5,7.6c-7.2,6.5-11.2,15.3-11.2,24.7c0,18.4,15,33.4,33.4,33.4S161.4,205.4,161.4,187z"/><path fill="#000000" d="M128,29.7c10.8,0,19.7,8.8,19.7,19.7v94v8.7l6.5,5.9c8.5,7.6,13.1,18,13.1,29.1c0,21.7-17.7,39.3-39.3,39.3S88.7,208.7,88.7,187c0-11.1,4.7-21.4,13.1-29.1l6.5-5.9v-8.7v-94C108.3,38.5,117.2,29.7,128,29.7 M128,10c-21.7,0-39.3,17.6-39.3,39.3v94C76.7,154.1,69,169.6,69,187c0,32.6,26.4,59,59,59c32.6,0,59-26.4,59-59c0-17.4-7.7-32.9-19.7-43.7v-94C167.3,27.6,149.7,10,128,10L128,10z"/>
                </g>
            </g>
        </svg>
    );
  }  else if (iconName === 'cross') {
    icon = (
        <svg
          viewBox="0 0 24 24"
          width='1em'
          height='1em'
          fill='currentColor'
        >
          <path d="M 20.1 6 L 18 3.9 l -6 6 l -6 -6 L 3.9 6 l 6 6 l -6 6 L 6 20.1 l 6 -6 l 6 6 l 2.1 -2.1 l -6 -6 l 6 -6 Z" />
        </svg>
    );
  }

  return icon;
};

Icon.propTypes = {
  iconName: PropTypes.string,
  color: PropTypes.string,
  scale: PropTypes.number,
  rotate: PropTypes.number,
};
Icon.defaultProps = {
  iconName: '',
  color: "#",
  scale: 1,
  rotate: 0,
};

export default Icon;
