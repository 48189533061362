import React from 'react';
import { SettingOutlined } from '@ant-design/icons';

const SettingsToggle = ({ setShowSettings }) => {
    return (
        <>
            
            <button className={'copyBtn'} onClick={() => setShowSettings(true)}>
                <SettingOutlined />
            </button>
            
        </>
    );
};

export default SettingsToggle;
