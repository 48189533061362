import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Radio, Tooltip, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { allProviders } from '../../constants/map-providers';
import { getParameters } from '../../reducers/parametersReducer';

const MapSettings = ({ handleChange }) => {
    const parameters = useSelector(getParameters);
    const { provider, mode, hideInactive, enableStatus, displayLabels, showTemperatures } = parameters;
  return (
    <Flex vertical gap="middle">
        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Map provider</div>
            <Select
                value={provider}
                onChange={(value) => handleChange('provider', value)}
                options={allProviders.map((p) => ({ key: p, value: p, label: p }))}
                style={{ minWidth: '110px' }}
            />
        </Flex>
        
        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Map mode</div>
            <Radio.Group value={mode} size="small" onChange={(e) => handleChange('mode', e.target.value)} buttonStyle="solid">
                <Radio.Button value="light">Light</Radio.Button>
                <Radio.Button value="dark">Dark</Radio.Button>
            </Radio.Group>
        </Flex>

        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Inactive vehicles</div>
            <Radio.Group value={hideInactive} size="small" onChange={(e) => handleChange('hideInactive', e.target.value)} buttonStyle="solid">
                <Radio.Button value={false}>Show</Radio.Button>
                <Radio.Button value={true}>Hide</Radio.Button>
            </Radio.Group>
        </Flex>

        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Marker labels <Tooltip placement="topLeft" title={'If vehicle display name is not available, marker labels will default to vehicle Number plate'}><InfoCircleOutlined /></Tooltip></div>
            <Radio.Group value={displayLabels} size="small" onChange={(e) => handleChange('displayLabels', e.target.value)} buttonStyle="solid">
                <Radio.Button value={'reg'}>Number plate</Radio.Button>
                <Radio.Button value={'name'}>Display name</Radio.Button>
            </Radio.Group>
        </Flex>

        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Show vehicle status <Tooltip placement="topLeft" title={'Display what is the current vehicle status (parked, moving, idling)'}><InfoCircleOutlined /></Tooltip></div>
            <Radio.Group value={enableStatus} size="small" onChange={(e) => handleChange('enableStatus', e.target.value)} buttonStyle="solid">
                <Radio.Button value={true}>Show</Radio.Button>
                <Radio.Button value={false}>Hide</Radio.Button>
            </Radio.Group>
        </Flex>

        <Flex horizontal gap="middle" justify={'space-between'} align={'flex-start'}>
            <div>Temperature data <Tooltip placement="topLeft" title={'Show a sidebar with temperature data for vehicles/trailers that have temperature sensors installed.'}><InfoCircleOutlined /></Tooltip></div>
            <Radio.Group value={showTemperatures} size="small" onChange={(e) => handleChange('showTemperatures', e.target.value)} buttonStyle="solid">
                <Radio.Button value={true}>Show</Radio.Button>
                <Radio.Button value={false}>Hide</Radio.Button>
            </Radio.Group>
        </Flex>
    </Flex>
  )
};

MapSettings.propTypes = {
    mode: PropTypes.string,
    hideInactive: PropTypes.bool,
    displayLabels: PropTypes.string,
    handleChange: PropTypes.func,
};

export default MapSettings;