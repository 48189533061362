import { createSlice } from '@reduxjs/toolkit';
import { allProviders } from '../constants/map-providers';
import { appCongig } from '../config/appConfig';

const isLatitude = (lat) => {
    return (lat || lat === 0) && isFinite(lat) && Math.abs(lat) <= 90;
};
  
const isLongitude = (lng) => {
    return (lng || lng === 0) && isFinite(lng) && Math.abs(lng) <= 180;
};

const urlParams = new URLSearchParams(window.location.search);
const userZoom = urlParams.get('zoom');
const userLat = urlParams.get('lat');
const userLng = urlParams.get('lng');
const userProvider = urlParams.get('provider');
const userMode = urlParams.get('mode');
const vehicleLabels = urlParams.get('labels');
const userHideInactive = urlParams.has('hideInactive');
const showTemperatures = urlParams.has('showTemperatures');
const enableStatus = urlParams.has('enableStatus');

// Map initial zoom 
// We use the following order -> zoom provided as an url parrameter -> zoom stored in the session -> default zoom value

// Map initial center
// We use the following order -> coordinates provided as an url parrameter -> coordinates stored in the session -> default coordinates

export const parametersReducer = createSlice({
  name: 'parameters',
  initialState: {
    mode: userMode || 'light',
    hideInactive: userHideInactive || false,
    displayLabels: vehicleLabels && vehicleLabels === 'name' ? 'name' : 'reg',
    showTemperatures: showTemperatures && window.innerWidth >= 640,
    enableStatus: enableStatus,
    provider: userProvider && allProviders.includes(userProvider) ? userProvider : appCongig.mapProvider,
    zoom: userZoom && userZoom <= 20 && userZoom >=4  ? userZoom : parseInt(sessionStorage.getItem("lastZoom")) || 4,
    lat: userLat || null,
    lng: userLng || null,
    center: isLatitude(userLat) && isLongitude(userLng) ? { lat: userLat, lng: userLng } : sessionStorage.getItem("lastCenter") ? JSON.parse(sessionStorage.getItem('lastCenter')) : { lat: 56, lng: 10 }
  },
  reducers: {
    updateParameter: (state, action) => ({
        ...state, ...action.payload
    }),
  },
});

export const { updateParameter } = parametersReducer.actions;
export const getParameters = (state) => state.parameters;
export default parametersReducer.reducer;
