import { useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

const MapEventHandler = ({ center, bounds, disableUserCentering }) => {
  const map = useMap();
  
  // Invalidate map size when any of the props change (mainly width and height will have any effect)
  useEffect(() => {
    setTimeout(() => { 
        map.invalidateSize({
          animate: true,
          duration: 0.5,
        }); 
    }, 250); 
  });

  // Move map on user location
  useEffect(() => {
    if(!disableUserCentering) {
      map.locate().on("locationfound", function (e) {
        if(!sessionStorage.getItem("lastZoom") || !sessionStorage.getItem("lastCenter")) {
          // map.flyTo(e.latlng, map.getZoom());
          map.flyTo(e.latlng, 18, { animate: false, duration: 1 });  // When animate is false duration is irrelevant
        }
      });
    }
  }, [map]);

  // Store latest zoom and center in sessionStorage so we can fly there in case of page reload
  const mapEvents = useMapEvents({
    zoomend: () => {
      if (!map) return;
      sessionStorage.setItem("lastZoom", mapEvents.getZoom());
      sessionStorage.setItem("lastCenter", JSON.stringify(mapEvents.getCenter()));
    },
    dragend: () => {
      if (!map) return;
      sessionStorage.setItem("lastZoom", mapEvents.getZoom());
      sessionStorage.setItem("lastCenter", JSON.stringify(mapEvents.getCenter()));
    },
});

  return null;
};

export default MapEventHandler;

