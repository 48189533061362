import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
const convert = require('color-convert');

const alertColors = {
  1: '#fce070',
  2: '#f8a400',
  3: '#f50a00'
}

const pin = (heading, alert) => {
    const rgb = convert.hex.rgb(alert ? alertColors[alert] : '#4069F8');
    return `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="-106 -106 724 724">
      <path transform="rotate(${heading} 256 256)" fill="rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})" d="M253.7 3.1c-2.1 1.8-4.9 4.8-6.2 6.8-3.4 5.4-231 477.7-232.9 483.4-2.4 7.3-2.1 12.8 1 16.2 2.4 2.6 2.9 2.7 8.8 2.3 12.6-1.1 15.7-2.9 106.1-65.9 33.6-23.4 63.5-44.1 66.5-46 9.8-6.2 22.6-12.8 31.9-16.4 26-10.1 42-8.6 72.1 6.5 13.3 6.7 16.7 9 87 59 67.7 48.2 74.6 52.9 85.2 58.2 8.8 4.4 17.3 5.9 21.2 3.8 4.7-2.5 5.9-10 3-18.7C494.7 484.5 279 12.8 276.1 8.5c-6-9-15.3-11.2-22.4-5.4z"/>
    </svg>`;
};

const icon = (color) => {
  const rgb = convert.hex.rgb(color || '#4069F8');
  return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26'><defs><style>.a{fill:rgb(${convert.hex.rgb('#ffffff')});}</style></defs><title>Map Marker Icon</title><circle fill='rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})' 
            cx='13' cy='13' r='13'/><circle class='a' cx='13' cy='13' r='7'/></svg>`;
};

const vehicleIcon = (heading, alert) => new L.Icon({
    iconUrl: encodeURI(`data:image/svg+xml,${pin(heading, alert)}`),
    iconRetinaUrl: encodeURI(`data:image/svg+xml,${pin(heading, alert)}`),
    iconAnchor: null,
    tooltipAnchor: [0, 14],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(32, 32), // Adjusted for rotating pin, real size 32x32
});

const iconParked = (color, alert) => new L.Icon({
    iconUrl: encodeURI(`data:image/svg+xml,${icon(alert ? alertColors[alert] : color)}`),
    iconRetinaUrl: encodeURI(`data:image/svg+xml,${icon(alert ? alertColors[alert] : color)}`),
    iconAnchor: null,
    tooltipAnchor: [0, 12],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(16, 16),
});

const markersGen = (vehicles, displayLabels) => vehicles.map((v) => {
  const label = displayLabels === 'name' ? v?.display_name : v?.registration?.id;
  return <Marker key={`marker-${v.vehicleId}`}
    //   onClick={() => {}}
      icon={v.status && v.status !== 'moving' ? iconParked(v.status === 'parked' ? '#CCCCCC' : '#4069F8', v.temperatureAlert) : vehicleIcon(v.dir, v.temperatureAlert)}
      position={{ lat: v.lat, lng: v.lng }}
    >
        { label
            ? <Tooltip
                closeButton={false}
                direction='bottom'
                permanent={true}
            >
                {label}
            </Tooltip>
            : null
        }
    </Marker>
  });

const VehicleMarkers = ({ vehicles, displayLabels }) => {
  return (
    markersGen(vehicles, displayLabels)
  )
};

VehicleMarkers.propTypes = {
    vehicles: PropTypes.array,
    displayLabels: PropTypes.string
};

export default VehicleMarkers;
