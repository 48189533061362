import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber } from 'lodash';
import { Tooltip } from 'antd';
import Icon from '../../Icon/Icon';

const getTargetTemp = (manualTarget, autoTarget) => {
  if (isNumber(autoTarget)) {
    return `${Math.round(autoTarget*10)/10}°C`;
  }
  if (isNumber(manualTarget)) {
    return `${Math.round(manualTarget*10)/10}°C`;
  }
  return '';
};

const getValueDeviation = (value, autoTarget, manualTarget) => {
  const target = isNumber(autoTarget) ? autoTarget : manualTarget;
  if (!isNumber(value) || !isNumber(target)) return '';
  if (Math.abs(value - target) >= 5) return 'high';
  if (Math.abs(value - target) >= 3) return 'medium';
  if (Math.abs(value - target) >= 1) return 'low';
  return '';
};

const TempRow = styled.div`
    display: flex;
    padding: 0;
    align-items: center;
    margin-bottom: 8px;
    .sensorName {
        font-weight: bold;
        color: #535354;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        overflow: hidden;
        display: flex;
        column-gap: 4px;
        align-items: center;
    }
    .labelContainer {
        min-width: 100px;
        color: #929497;
        font-size: 12px;
    }
    .dataTimestamp {
      display: flex;
      column-gap: 4px;
      align-items: center;
      span {
        line-height: 20px;
      }
    }
    .valueContainer, .targetContainer {
        font-weight: bold;
        color: #535354;
        font-size: 16px;
        flex: 1;
        text-align: end;
        cursor: pointer;
    }
    .valueWrap {
      border-radius: 8px;
      padding: 4px 8px;
      background-color: #edecf1;
      &.low {
        background-color: #fce070;
      }
      &.medium {
        background-color: #f8a400;
      }
      &.high {
        background-color: #f50a00;
        color: #fff;
      }
    }
    .targetIndicator {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #edecf1;
      &.auto {
        background-color: #00af61;
      }
    }
`;

const TemperatureRow = ({ data, sensorInfo }) => {
  const { value, position, timestamp, target } = data;
  const targetTemp = getTargetTemp(sensorInfo?.target, target);
  return <TempRow>
    <div className={'labelContainer'}>
      <div className={'sensorName'}>
        <div className={`targetIndicator ${target !== undefined ? 'auto' : ''}`}></div>
        {sensorInfo?.name || `Sensor ${position || ''}`}
      </div>
      <div className={'dataTimestamp'}>
        {timestamp
          ? <>
            <Icon iconName='update-time' scale={2.5} color={'#535354'}/>
            <span>{moment(timestamp).format('HH:mm - L')}</span>
          </>
          : null
        }
      </div>
    </div>
    <div className={'targetContainer'}>
      <Tooltip placement={'left'} title={'Target temperature'}>
        {targetTemp}
      </Tooltip>
    </div>
    <div className={'valueContainer'}>
      <Tooltip placement={'left'} title={'Current temperature'}>
        <span className={`valueWrap ${getValueDeviation(value, target, sensorInfo?.target)}`}>
          {`${Math.round(value*10)/10}°C`}
        </span>
      </Tooltip>
    </div>
  </TempRow>;
};

const TemperatureData = ({ vehicle }) => {
  const { data, target_interior_temperatures } = vehicle;
  return <div className={'TemperatureData'}>
    {data?.interior_temperature_degrees_c?.value?.length
      ? data.interior_temperature_degrees_c.value.map((x, i) => <TemperatureRow key={`temp-row-${i}`} data={x} sensorInfo={target_interior_temperatures ? target_interior_temperatures.find((s) => s.position === x.position) : null } />)
      : null
    }
  </div>;
};
TemperatureData.propTypes = {
  vehicle: PropTypes.object,
};

TemperatureRow.propTypes = {
  data: PropTypes.object,
  sensorInfo: PropTypes.object,
  convertTemperature: PropTypes.func,
  t: PropTypes.func,
};

export default TemperatureData;
