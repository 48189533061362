import React from 'react';
import PropTypes from 'prop-types';

const MapError = (provider) => {
    return (
        <div style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            textAlign: 'center',
            alignContent: 'center'
        }}> Reloading map... </div>
    );
};

MapError.propTypes = {
    provider: PropTypes.string,
};

export default MapError;