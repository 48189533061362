import React from 'react';
import { MapContainer as MapLeaflet, TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { isMobile } from 'react-device-detect';
import * as $PROVIDERS from '../../constants/map-providers';
import * as $KEYS from '../../constants/map-keys';
import MapError from './MapError';

import { ErrorBoundary } from "react-error-boundary";

const LeafletMap = ({ children, provider, ...props }) => {   
    if (provider === $PROVIDERS.GOOGLE) {
      return (
        <ErrorBoundary fallback={<MapError provider={provider}/>}>
          <MapLeaflet
            {...props}
          >            
            {/* This will render the map only without the option to change layers */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: $KEYS.GOOGLE, version: 'weekly', language: 'en' }}
              apiKey={$KEYS.GOOGLE}
              type='roadmap'
              useGoogMapsLoader={true}
            />
            {children}
          </MapLeaflet>
        </ErrorBoundary>
      );
    }
    if (provider === $PROVIDERS.LOCATION_IQ) {
      return (
        <ErrorBoundary fallback={<MapError provider={provider}/>}>
          <MapLeaflet
            {...props}
          >
            <TileLayer
              maxZoom={20}
              url={`https://{s}-tiles.locationiq.org/v2/{scheme}/r/{z}/{x}/{y}{tileResolution}.{format}?key=${$KEYS.LOCATION_IQ}`}
              attribution={isMobile
                ? ['<a href=&quot;https://www.openstreetmap.org/copyright&quot; target=&quot;_blank&quot;>OpenStreetMap</a>']
                : ['<a href=&quot;https://locationiq.com&quot; target=&quot;_blank&quot;>LocationIQ Maps</a>',
                  '<a href=&quot;https://www.openstreetmap.org/copyright&quot; target=&quot;_blank&quot;>OpenStreetMap</a> contributors']}
              scheme="obk"
              tileResolution="@2x"
              format="png"/>
            {children}
          </MapLeaflet>
        </ErrorBoundary>
      );
    }
    if (provider === $PROVIDERS.OSM) {
      return (
        <ErrorBoundary fallback={<MapError provider={provider}/>}>
          <MapLeaflet
            {...props}
          >
            <TileLayer
              maxZoom={18}
              url={'https://tile.openstreetmap.org/{z}/{x}/{y}.png'}
              attribution={['&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors']}
            />
            {children}
          </MapLeaflet>
        </ErrorBoundary>
      );
    }
    return null;
};

LeafletMap.propTypes = {

};

export default LeafletMap;
