import React from 'react';
import AntdIcon from '@ant-design/icons';
import IconLibrary from './IconLibrary';

// Legacy support if needed
export const Icon = ({ color, scale, iconName, ...restProps }) => {
  
  const iconScale = scale || 3;
  
  return <AntdIcon
    component={ () => IconLibrary({ iconName }) }
    style={{ color, fontSize: `${iconScale * 8}px` }}
    {...restProps}
  />;
};

export default Icon;
